<template lang="pug">
    div.main-container(:style="{backgroundSize:'cover', backgroundImage:'url(' + require('@/assets/img2/insigne-bg-stroke-grey.png') + ')'} ")
        div.overlay
       
        div.main-content(id="content")
            div.layout-px-spacing
                div.row.mt-3
                    div.col-lg-12.col-md-12.layout-spacing
                        div.widget.box.box-shadow
                            div.widget-header
                                div.row
                                    div.col-xl-12.col-md-12.col-sm-12.col-12.text-center
                                div.widget-content.widget-content-area
                                    div.col-lg-12.mx-auto
                                        iframe(id="targetFrame" :src="'../'+examData.sQuestionPath " width="1260px" height="640px")
                            div.row.mx-auto.text-center
                                div.col-md-4
                                    div
                                        p
                                            svg(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round").feather.feather-clock
                                                circle(cx="12" cy="12" r="10")
                                                polyline(points="12 6 12 12 16 14")
                                            span(style="padding-left: 5px") {{formattedElapsedTime}}

                                div.col-md-4
                                    
                                
                                div.col-md-4
                                    div.btn-group.mt-n2
                                        button(@click="nextQuestion()" v-if="examData.bLast == false").btn.btn-light Guardar
</template>
<script>
/* eslint-disable */
export default {
    name: 'SingleQuestion',
    props: ['idResultQuestion', 'idResult', 'idExamPool'],
    components: {
    },    
    data() {
        return {
            examData: {},
            elapsedTime: 0,
            timer: undefined,
            iTotalQuestions: 0,
            iTotalTime: 0,
            iUsedTime: 0
        }
    },
    computed: {
        formattedElapsedTime() {
            
            const date = new Date(null);
            date.setSeconds(this.elapsedTime / 1000);
            const utc = date.toUTCString();
            if(this.elapsedTime > this.iTotalTime){
                console.log('hola')
                clearInterval(this.timer);
                this.FinishQuestionary()
                return
            }
            
            return utc.substr(utc.indexOf(":") - 2, 8);
            
        }
    },    
    methods: {
        async getData(){
            
            await axios.get(apiURL + 'Student/getSingleQuestiondata?idResultQuestion='+ this.idResultQuestion, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                if(response.data.sErrors.length > 0){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.sErrors[0],
                        footer: ''
                    })
                    return                    
                }
                this.examData = response.data.examData[0]
                this.elapsedTime = response.data.iUsedTime * 1000 
                this.start()
                this.iTotalTime = response.data.iTotalTime * 1000
                this.iUsedTime = response.data.iUsedTime
                
            } )
            .catch( (error)  => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
                return                    
            } )

        },
        async nextQuestion(){
            var ventana = document.getElementById('targetFrame').contentWindow
            var iScore = ventana.AtomiAP.presentations[ventana.AtomiAP.presentations.length-1].variable("apQuizScore");
            
            await axios.post(apiURL + 'Student/saveSingleQuestion?idResultQuestion=' + this.examData.idResultQuestion + '&iScore=' + iScore + '&iUsedTime=' + this.elapsedTime/1000 , null, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                if(response.data.sErrors.length > 0){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.sErrors[0],
                        footer: ''
                    })
                    return                    
                }                
                router.push('/student/QuestionsList/'+ this.idExamPool + '/' + this.idResult)

            } )
            .catch( (error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
                return                    
            } )                        
        },
        async FinishQuestionary(){
            var ventana = document.getElementById('targetFrame').contentWindow
            var iScore = ventana.AtomiAP.presentations[ventana.AtomiAP.presentations.length-1].variable("apQuizScore");
            await axios.post(apiURL + 'Student/finishQuestionary?idResultQuestion=' + this.examData.idResultQuestion + '&iScore=' + iScore + '&idResult=' + this.examData.idResult + '&iTotalTime=' + this.elapsedTime/1000, null, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                if(response.data.sErrors.length > 0){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.sErrors[0],
                        footer: ''
                    })
                    return                    
                } 

                axios.get(apiURL + 'Student/getUserResultData?idResult=' + this.idResult, { headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.userToken
                } })
                .then( (response) => {
                    if(response.data.sErrors.length > 0){
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.data.sErrors[0],
                            footer: ''
                        })
                        return                    
                    }

                    console.log('Examen terminado')
                    router.push('/student/Results/' + response.idPersonExam + '/' + response.idGroupDetail)
                } )
                .catch( (error)  => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error,
                        footer: ''
                    })
                    return                    
                } )                
            } )
            .catch( (error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
                return                    
            } )            

        },          
        start() {
            this.timer = setInterval(() => {
                this.elapsedTime += 1000;
            }, 1000);
        },
    },
    mounted() {
        console.log(this.idResultQuestion)
       //this.checkPermisions(this.$router.currentRoute.path)
       this.getData()
       
    }
}
</script>